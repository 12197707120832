import { asManagerProfile } from 'types/Manager/ManagerProfileDetails'
import { getManagerFromStore } from './getManagerFromStore'
import { getManagerStatistics } from './getManagerStatistics'
import type { SupabaseClient } from '@supabase/supabase-js'
import type { Database } from 'integrations/supabase/generated.types'

export async function getManagerProfile(userId: string | undefined, supabase: SupabaseClient<Database>) {
  const { managerData, errorCode } = await getManagerFromStore(userId, supabase)
  const statistics =
    managerData?.address && managerData.chainId
      ? await getManagerStatistics(managerData.chainId, managerData.address)
      : null

  return {
    manager: asManagerProfile(managerData, statistics),
    errorCode,
  }
}
