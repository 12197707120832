import { Separator } from 'components/atoms/Separator'
import { ExitIcon } from 'components/atoms/Icons'
import styled from 'styled-components'
import { Button } from 'components/atoms/Button'
import { useUser } from '@supabase/auth-helpers-react'
import { Row } from 'components/atoms/Row'
import { UserAvatar, UserSection, Username } from 'components/Login/UserAvatar'
import { NotConnected } from 'components/molecules/NotConnected'

interface Props {
  onConnect: () => void
  onLogout: () => void
}

export function UserAccountSection({ onLogout, onConnect }: Props) {
  const user = useUser()

  return (
    <>
      <ConnectSection>
        <NotConnected />
        <ModalConnectButton view="secondary" onClick={onConnect}>
          Connect wallet
        </ModalConnectButton>
      </ConnectSection>
      {user && (
        <UserSection>
          <UserAvatar />
          <Username bold>{user.email}</Username>
        </UserSection>
      )}
      <Separator />
      <Button view="danger" icon={<ExitIcon />} onClick={onLogout} wide>
        Log out
      </Button>
    </>
  )
}

const ConnectSection = styled(Row)`
  gap: 8px;
  justify-content: space-between;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`
const ModalConnectButton = styled(Button)`
  min-width: 150px;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    width: 100%;
  }
`
