import { ModalStateWrapper, StatusModalContent, StatusTitle } from 'components/organisms/Modals'
import { Text } from 'components/atoms/Text'
import { ExitIcon } from 'components/atoms/Icons'
import { Buttons, ModalButton } from '../ModalButtons'
import { Row } from 'components/atoms/Row'
import styled from 'styled-components'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useWalletConnector } from 'providers/WalletConnectorProvider'
import { useIsConnectedManager } from './hooks/useIsConnectedManager'
import { Loader } from 'components/molecules/Loader'

export interface LogoutProps {
  onClose: () => void
}

export const LogoutSection = ({ onClose }: LogoutProps) => {
  const { deactivate, account } = useWalletConnector()
  const { isConnectedManager, isLoading } = useIsConnectedManager(!!account)
  const supabase = useSupabaseClient()

  if (isLoading) {
    return <StyledLoader size={40} centered />
  }

  const onLogout = async () => {
    if (isConnectedManager) {
      deactivate()
    }
    onClose()
    if (supabase) {
      await supabase.auth.signOut()
    }
  }

  const actionMessage = `Log out${isConnectedManager ? ' and disconnect' : ''}`

  return (
    <StatusModalContent>
      <IconWrapper>
        <ExitIcon size={64} />
      </IconWrapper>
      <ModalStateWrapper>
        <StatusTitle>{`${actionMessage}?`}</StatusTitle>
        <Text color="extra-light">{`Are you sure you want to log out${
          isConnectedManager ? ' and disconnect your wallet' : ''
        }?`}</Text>
      </ModalStateWrapper>
      <Buttons>
        <ModalButton view="tertiary" onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton view="warning" onClick={onLogout}>
          {actionMessage}
        </ModalButton>
      </Buttons>
    </StatusModalContent>
  )
}

const IconWrapper = styled(Row)`
  color: ${({ theme }) => theme.colors.PersianRed};
`

const StyledLoader = styled(Loader)`
  margin-bottom: 64px;
`
