import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useQuery } from '@tanstack/react-query'
import { getManagerProfile } from 'backend/managers/getManager'
import type { Database } from 'integrations/supabase/generated.types'

export const useManager = (userId: string | null | undefined) => {
  const supabase = useSupabaseClient<Database>()

  const { data, isInitialLoading } = useQuery({
    queryKey: ['manager', userId],
    enabled: !!userId,
    queryFn: () => {
      if (!userId) return null
      return getManagerProfile(userId, supabase)
    },
  })

  return { manager: data?.manager, errorCode: data?.errorCode, isLoading: isInitialLoading }
}
