import { Row } from 'components/atoms/Row'
import styled from 'styled-components'
import { Text } from 'components/atoms/Text'
import { LinkButton } from 'components/atoms/LinkButton'

interface LearnWalletLinkProps {
  className?: string
}

export const LearnWalletLink = ({ className }: LearnWalletLinkProps) => {
  return (
    <Wrapper className={className}>
      <Text color="light">New to Ethereum?</Text>
      <LinkButton href="https://ethereum.org/en/wallets/" blank>
        Learn more about wallets
      </LinkButton>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  gap: 4px;
  justify-content: flex-start;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    flex-direction: column;
    gap: 0;
  }
`
