import type { getManagerFromStore } from 'backend/managers/getManager/getManagerFromStore'
import { getManagerStatistics } from 'backend/managers/getManager/getManagerStatistics'
import { Database } from 'integrations/supabase/generated.types'
import { asSingleMember } from 'integrations/supabase/utils/databaseQueryResult'
import { SupportedChainId } from 'constants/chain'

export interface ManagerProfileDetails {
  id: number
  name: string
  address: string | null
  chainId: SupportedChainId | null
  userId: string
  imageUrl: string | null
  team: ManagerTeam | null
  highlights: ManagerHighlight[]
  contactMethods: ContactMethod[]
  attachments: Attachment[]
  descriptionSections: DescriptionSection[]
  totalRaised: string | null
  portfoliosLaunched: number | null
}

export interface ManagerTeam {
  description: string
  members: ManagerTeamMember[]
}

export interface ManagerTeamMember {
  name: string
  role: string
  linkedin: string | null
  imageUrl: string | null
}

export interface ManagerHighlight {
  title: string
  description: string
}

export interface Attachment {
  url: string
  filename: string
  type: 'file' | 'link'
}

export interface DescriptionSection {
  title: string
  content: string
}

export type ManagerStatistics = {
  totalRaised: string
  portfoliosLaunched: number
}

export type ContactType = Database['public']['Enums']['ContactType']

export interface ContactMethod {
  value: string
  service: ContactType
}

export type ManagerStorageData = Awaited<ReturnType<typeof getManagerFromStore>>['managerData']
export type ManagerStatisticsData = Awaited<ReturnType<typeof getManagerStatistics>>

export function asManagerProfile(
  data: ManagerStorageData,
  statistics: ManagerStatisticsData | null,
): ManagerProfileDetails | null {
  if (!data) {
    return null
  }

  const highlights = data.ManagerHighlight
  const contactMethods = data.ContactMethod
  const team = asSingleMember(data.ManagerTeam)
  const members = team?.TeamMember ?? []
  const attachments = data.ManagerAttachment
  const descriptionSections = data.ManagerDescriptionSection
  const totalRaised = statistics?.totalRaised ?? null
  const portfoliosLaunched = statistics?.portfoliosLaunched ?? null

  return {
    id: data.id,
    name: data.name,
    address: data.address,
    chainId: data.chainId,
    userId: data.userId,
    imageUrl: data.imageUrl,
    team: team && {
      members,
      description: team.description,
    },
    contactMethods,
    highlights,
    attachments,
    descriptionSections,
    totalRaised,
    portfoliosLaunched,
  }
}
