import styled from 'styled-components'
import { Text } from 'components/atoms/Text'

export function ConnectedWith({ activeConnector }: { activeConnector: string }) {
  return (
    <Container>
      <Eclipse />
      <Text color="dark" bold>
        Connected with {activeConnector}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Eclipse = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.Emerald};
`
