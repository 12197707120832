import * as SeparatorPrimitive from '@radix-ui/react-separator'
import styled, { css } from 'styled-components'
import { UseTransientProps } from 'types/utility'

interface SeparatorElementProps {
  orientation?: 'horizontal' | 'vertical'
  spaceStart?: number
  spaceEnd?: number
  color?: string
}

export interface SeparatorProps extends SeparatorElementProps {
  className?: string
}

export const Separator = ({ orientation = 'horizontal', spaceStart, spaceEnd, color, ...props }: SeparatorProps) => {
  return (
    <SeparatorElement
      orientation={orientation}
      $spaceStart={spaceStart}
      $spaceEnd={spaceEnd}
      $color={color}
      decorative
      {...props}
    />
  )
}

type SeparatorElementSafeProps = UseTransientProps<SeparatorElementProps, 'spaceStart' | 'spaceEnd' | 'color'>

const SeparatorElement = styled(SeparatorPrimitive.Root)<
  Pick<SeparatorElementSafeProps, '$spaceStart' | '$spaceEnd' | '$color'>
>`
  background-color: ${({ theme, $color }) => $color ?? theme.colors.Mouse};

  &[data-orientation='horizontal'] {
    min-height: 1px;
    max-height: 1px;
    width: 100%;

    ${({ $spaceStart }) =>
      $spaceStart &&
      css`
        margin-top: ${$spaceStart}px;
      `}

    ${({ $spaceEnd }) =>
      $spaceEnd &&
      css`
        margin-bottom: ${$spaceEnd}px;
      `}
  }

  &[data-orientation='vertical'] {
    height: 100%;
    min-width: 1px;
    max-width: 1px;

    ${({ $spaceStart }) =>
      $spaceStart &&
      css`
        margin-left: ${$spaceStart}px;
      `}

    ${({ $spaceEnd }) =>
      $spaceEnd &&
      css`
        margin-right: ${$spaceEnd}px;
      `}
  }

  & + & {
    display: none;
  }
`
