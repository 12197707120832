import { css } from 'styled-components'
import { BorderRadiuses, Transitions, shadows, zIndexes } from 'styles'

type CommonInputProps = {
  disabled?: boolean
  error?: string
}

export const commonInputBorderStyles = css<CommonInputProps>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error, disabled, theme }) => (error && !disabled ? theme.colors.PersianRed : theme.colors.Iron)};
  border-radius: ${BorderRadiuses.s};
  transition: ${Transitions.all};

  &:hover {
    border-color: ${({ error, disabled, theme }) =>
      error ? theme.colors.PersianRed : disabled ? theme.colors.Iron : theme.colors.HeavyMetal};
  }
  &:focus-visible {
    border-color: ${({ error, disabled, theme }) =>
      error ? theme.colors.PersianRed : disabled ? theme.colors.Iron : theme.colors.HeavyMetal};
  }
  &:focus-within {
    border-color: ${({ error, disabled, theme }) =>
      error ? theme.colors.PersianRed : disabled ? theme.colors.Iron : theme.colors.HeavyMetal};
  }
`

export const commonInputWrapperStyles = css<CommonInputProps>`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.Corduroy)};
  transition: ${Transitions.all};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.Corduroy)};
  }
  &:focus-visible {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  }
  &:focus-within {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  }
`

export const commonInputStyles = css<CommonInputProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.Iron : theme.colors.HeavyMetal)};
  transition: ${Transitions.all};
  cursor: inherit;

  &::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.Iron};
    transition: ${Transitions.all};
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`

export const popupTriggerContainer = css`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  height: fit-content;
  user-select: none;
  cursor: pointer;

  @media ${({ theme }) => theme.queries.touchscreen} {
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      width: 48px;
      height: 48px;
      transform: translate(-50%, -50%);
    }
  }
`

interface PopupContentProps {
  $stretch?: boolean
  $buttonTooltip?: boolean
}

export const popupContent = css<PopupContentProps>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  ${({ $stretch }) =>
    !$stretch &&
    css`
      max-width: 300px;
    `}
  padding: 24px;
  border-radius: ${BorderRadiuses.s};
  background-color: ${({ theme }) => theme.colors.NotificationBackground};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.White};
  box-shadow: ${shadows.m};
  z-index: ${zIndexes.modalContent};
  svg {
    fill: ${({ theme }) => theme.colors.NotificationBackground};
  }
  font-weight: 400;
  overflow: hidden;
  white-space: normal;
`
