import { ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalProps } from 'components/organisms/Modals'
import { StatusIcon } from 'components/molecules/StatusIcon'
import { LoaderIcon } from 'components/atoms/Icons'
import { Column } from 'components/atoms/Column'
import { Text } from 'components/atoms/Text'

export type StatusModalState = 'Fail' | 'Success' | 'Pending' | 'Rejected'

export interface StatusModalChildrenProps {
  closeModal: () => void
}

export interface StatusModalProps extends Pick<ModalProps, 'nonCloseable' | 'width' | 'onClose'> {
  state: StatusModalState
  title?: string
  info?: ReactNode
  children?: ReactNode | ((props: StatusModalChildrenProps) => ReactNode)
  message?: ReactNode
  centerMessage?: boolean
  iconSize?: number
}

export const StatusModal = ({
  state,
  title,
  info,
  width,
  children,
  message,
  nonCloseable,
  centerMessage,
  onClose,
  iconSize,
}: StatusModalProps) => {
  const [opened, setOpened] = useState(true)

  const closeModal = useCallback(() => setOpened(false), [])

  const modalContent = (
    <StatusModalContent>
      <StateIcon state={state} iconSize={iconSize} />
      <ModalStateWrapper centerMessage={centerMessage}>
        <StatusTitle>{title ?? getStatusTitle(state)}</StatusTitle>
        {message && <StatusText color="extra-light">{message}</StatusText>}
      </ModalStateWrapper>
      {info && <InfoBox>{info}</InfoBox>}
      {typeof children === 'function' ? children({ closeModal }) : children}
    </StatusModalContent>
  )

  return (
    <StyledModal
      view="secondary"
      width={width}
      content={modalContent}
      opened={opened}
      toggleOpened={setOpened}
      nonCloseable={nonCloseable}
      onClose={onClose}
    />
  )
}

const StateIcon = ({ state, iconSize }: { state: StatusModalState; iconSize?: number }) => {
  switch (state) {
    case 'Success':
      return <StatusIcon type="success" iconSize={iconSize} />
    case 'Fail':
      return <StatusIcon type="fail" iconSize={iconSize} />
    case 'Rejected':
      return <StatusIcon type="rejected" iconSize={iconSize} />
    case 'Pending':
      return <LoaderIcon />
  }
}

const getStatusTitle = (state: StatusModalState) => {
  switch (state) {
    case 'Fail':
      return 'Failed'
    case 'Pending':
      return 'Pending'
    case 'Success':
      return 'Success!'
    case 'Rejected':
      return 'Rejected'
  }
}

export const StyledModal = styled(Modal)`
  ${ModalHeader} {
    position: absolute;
    border-bottom: 0;
  }
`

export const StatusModalContent = styled(Column)`
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.Edward};
  gap: 24px;

  & > p {
    margin: 0;
  }
`

export const ModalStateWrapper = styled(Column)<{ centerMessage?: boolean }>`
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: ${({ centerMessage }) => (centerMessage ? 'center' : 'inherit')};
`

export const StatusTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.White};
  font-weight: 800;
  text-align: center;
`

const InfoBox = styled(Column)`
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.InfoBoxBackground};
`
export const StatusText = styled(Text)`
  font-weight: 400;
`
