import { forwardRef, MouseEvent, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import styled, { css } from 'styled-components'

import { BorderRadiuses, Transitions } from 'styles'
import { UseTransientProps } from 'types/utility'

import { LoadingCircleIcon } from '../Icons'
import { ButtonInnerContainer, IconComponent } from '../Button'

interface LinkButtonStyleProps {
  wide?: boolean
  narrow?: boolean
  loading?: boolean
  inline?: boolean
}

export interface LinkButtonProps extends LinkButtonStyleProps, LinkProps {
  children?: ReactNode
  className?: string
  view?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link'
  size?: 'small' | 'medium' | 'large'
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  blank?: boolean
  noref?: boolean
  download?: boolean
  disabled?: boolean
  onPress?: (event: MouseEvent) => void
  tabIndex?: number
}

type LinkButtonSafeStyleProps = Omit<UseTransientProps<LinkButtonStyleProps, 'loading' | 'wide' | 'narrow'>, 'inline'>

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(function LinkButton(
  {
    children,
    href,
    className,
    view = 'link',
    size = 'medium',
    narrow,
    wide,
    icon,
    iconPosition,
    loading,
    inline,
    blank,
    noref,
    download,
    onPress,
    disabled,
    tabIndex,
  },
  ref,
) {
  return (
    <LinkComponent inline={inline} wide={wide} disabled={disabled}>
      <Link href={href} passHref legacyBehavior>
        <LinkButtonContainer
          tabIndex={tabIndex}
          className={className}
          view={view}
          size={size}
          $wide={wide}
          $narrow={narrow}
          icon={icon}
          $loading={loading}
          ref={ref}
          download={download}
          disabled={disabled || loading}
          target={blank ? '_blank' : undefined}
          rel={noref ? 'noopener noreferrer' : undefined}
          onClick={(event) => {
            if (disabled) {
              event.preventDefault()
            }
            if (loading || !href) {
              onPress?.(event)
            }
          }}
        >
          {loading ? (
            <LoadingCircleIcon />
          ) : (
            <ButtonInnerContainer isIconButton={!children}>
              {children}
              {icon && <IconComponent icon={icon} iconPosition={iconPosition} isIconButton={!children} />}
            </ButtonInnerContainer>
          )}
        </LinkButtonContainer>
      </Link>
    </LinkComponent>
  )
})

const LinkComponent = styled.div<Pick<LinkButtonProps, 'inline' | 'wide' | 'disabled'>>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'flex')};
  align-items: center;
  position: relative;
  width: ${({ wide }) => (wide ? '100%' : 'fit-content')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const commonLinkButtonStyles = css<LinkButtonSafeStyleProps>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: ${({ $wide }) => ($wide ? '100%' : 'fit-content')};
  margin: 0;
  padding: 0;
  border: none;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  transition: ${Transitions.all};
  outline: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
`

const expandedLinkButtonStyles = css<Pick<LinkButtonProps, 'size' | 'icon'> & LinkButtonSafeStyleProps>`
  min-width: ${({ size, $narrow }) => {
    if ($narrow) return '32px'
    switch (size) {
      case 'small':
        return '132px'
      case 'medium':
      case 'large':
      default:
        return '208px'
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'medium':
      default:
        return '40px'
      case 'small':
        return '32px'
      case 'large':
        return '48px'
    }
  }};

  padding: ${({ size, $narrow }) => {
    if ($narrow) return '4px'
    switch (size) {
      case 'small':
        return '4px 16px'
      case 'medium':
      default:
        return '8px 16px'
      case 'large':
        return '12px 16px'
    }
  }};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: ${BorderRadiuses.s};
`

const DefaultLinkButtonStyles = css<Pick<LinkButtonProps, 'disabled'> & LinkButtonSafeStyleProps>`
  ${commonLinkButtonStyles};
  text-decoration: underline;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.Iron : theme.colors.Elm)};
  background-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.OxidizedGreen};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.OxidizedGreen};
  }

  &:active {
    color: ${({ theme }) => theme.colors.OxidizedGreen};
  }
`

const PrimaryLinkButtonStylesHover = css`
  background-color: ${({ theme }) => theme.colors.CopperOrange};
  border-color: ${({ theme }) => theme.colors.CopperOrange};
  color: ${({ theme }) => theme.colors.White};
`

const PrimaryLinkButtonStylesDisabledHover = css`
  background-color: ${({ theme }) => theme.colors.GrayNurse};
  border-color: ${({ theme }) => theme.colors.GrayNurse};
  color: ${({ theme }) => theme.colors.Iron};
`

const PrimaryLinkButtonStyles = css<Pick<LinkButtonProps, 'disabled'> & LinkButtonSafeStyleProps>`
  ${commonLinkButtonStyles}
  ${expandedLinkButtonStyles}

  background-color: ${({ theme }) => theme.colors.HeavyMetal};
  border-color: ${({ theme }) => theme.colors.HeavyMetal};
  color: ${({ theme }) => theme.colors.White};

  &:hover {
    ${PrimaryLinkButtonStylesHover}
  }

  &:focus-visible {
    ${PrimaryLinkButtonStylesHover}
  }

  &:active {
    ${PrimaryLinkButtonStylesHover}
  }

  &:not([href]) {
    ${PrimaryLinkButtonStylesDisabledHover}
    &:hover {
      ${PrimaryLinkButtonStylesDisabledHover}
    }

    &:focus-visible {
      ${PrimaryLinkButtonStylesDisabledHover}
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.GrayNurse};
      border-color: ${({ theme }) => theme.colors.Iron};
      color: ${({ theme }) => theme.colors.Iron};
    `};
`

const SecondaryLinkButtonStyles = css<Pick<LinkButtonProps, 'disabled'> & LinkButtonSafeStyleProps>`
  ${commonLinkButtonStyles}
  ${expandedLinkButtonStyles}

  background-color: ${({ theme }) => theme.colors.White};
  border-color: ${({ theme }) => theme.colors.HeavyMetal};
  color: ${({ theme }) => theme.colors.HeavyMetal};

  &:hover {
    ${PrimaryLinkButtonStylesHover}
  }

  &:focus-visible {
    ${PrimaryLinkButtonStylesHover}
  }

  &:active {
    ${PrimaryLinkButtonStylesHover}
  }

  &:not([href]) {
    ${PrimaryLinkButtonStylesDisabledHover}
    &:hover {
      ${PrimaryLinkButtonStylesDisabledHover}
    }

    &:focus-visible {
      ${PrimaryLinkButtonStylesDisabledHover}
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.GrayNurse};
      border-color: ${({ theme }) => theme.colors.Iron};
      color: ${({ theme }) => theme.colors.Iron};
    `};
`

const TertiaryLinkButtonStylesDisabledHover = css`
  background-color: ${({ theme }) => theme.colors.CapeCod};
  border-color: ${({ theme }) => theme.colors.CapeCod};
  color: ${({ theme }) => theme.colors.Corduroy};
`

const TertiaryLinkButtonStyles = css<Pick<LinkButtonProps, 'disabled'> & LinkButtonSafeStyleProps>`
  ${commonLinkButtonStyles}
  ${expandedLinkButtonStyles}

  background-color: ${({ theme }) => theme.colors.HeavyMetal};
  border-color: ${({ theme }) => theme.colors.White};
  color: ${({ theme }) => theme.colors.White};

  &:hover {
    ${PrimaryLinkButtonStylesHover}
  }

  &:focus-visible {
    ${PrimaryLinkButtonStylesHover}
  }

  &:active {
    ${PrimaryLinkButtonStylesHover}
  }

  &:not([href]) {
    ${TertiaryLinkButtonStylesDisabledHover}
    &:hover {
      ${TertiaryLinkButtonStylesDisabledHover}
    }

    &:focus-visible {
      ${TertiaryLinkButtonStylesDisabledHover}
    }
  }
`

const DangerLinkButtonStylesHover = css`
  background-color: ${({ theme }) => theme.colors.PersianRed};
  border-color: ${({ theme }) => theme.colors.PersianRed};
  color: ${({ theme }) => theme.colors.White};
`

const DangerLinkButtonStyles = css<Pick<LinkButtonProps, 'disabled'> & LinkButtonSafeStyleProps>`
  ${commonLinkButtonStyles}
  ${expandedLinkButtonStyles}

  background-color: ${({ theme }) => theme.colors.White};
  border-color: ${({ theme }) => theme.colors.PersianRed};
  color: ${({ theme }) => theme.colors.PersianRed};

  &:hover {
    ${DangerLinkButtonStylesHover}
  }

  &:focus-visible {
    ${DangerLinkButtonStylesHover}
  }

  &:active {
    ${DangerLinkButtonStylesHover}
  }

  &:not([href]) {
    ${PrimaryLinkButtonStylesDisabledHover}
    &:hover {
      ${PrimaryLinkButtonStylesDisabledHover}
    }

    &:focus-visible {
      ${PrimaryLinkButtonStylesDisabledHover}
    }
  }
`
export const LinkButtonContainer = styled.a<Omit<LinkButtonProps & LinkButtonSafeStyleProps, 'href'>>`
  ${({ view }) => {
    switch (view) {
      case 'link':
      default:
        return DefaultLinkButtonStyles
      case 'primary':
        return PrimaryLinkButtonStyles
      case 'secondary':
        return SecondaryLinkButtonStyles
      case 'tertiary':
        return TertiaryLinkButtonStyles
      case 'danger':
        return DangerLinkButtonStyles
    }
  }};
  cursor: ${({ $loading }) => ($loading ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:not([href]) {
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }

    &:focus-visible {
      cursor: not-allowed;
    }
  }
}
`
