import type { ReactNode } from 'react'
import { useCallback } from 'react'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import styled from 'styled-components'

import { CloseIcon } from 'components/atoms/Icons'
import { zIndexes } from 'styles'
import { Button } from 'components/atoms/Button'

export interface ModalProps {
  view?: 'primary' | 'secondary'
  width?: number
  trigger?: ReactNode
  title?: string
  description?: string
  content: ReactNode
  footer?: ReactNode
  nonCloseable?: boolean
  hideHeader?: boolean
  opened?: boolean
  toggleOpened?: (open: boolean) => void
  className?: string
  onClose?: () => void
}

export const Modal = ({
  className,
  view = 'primary',
  width = 560,
  trigger,
  title,
  description,
  content,
  footer,
  opened,
  toggleOpened,
  nonCloseable,
  hideHeader = false,
  onClose,
}: ModalProps) => {
  const _onClose = useCallback(
    (event: Event) => {
      if (nonCloseable) {
        event.preventDefault()
      }
      onClose?.()
    },
    [nonCloseable, onClose],
  )

  return (
    <DialogPrimitive.Root open={opened} onOpenChange={toggleOpened}>
      {trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}
      <DialogPrimitive.Portal>
        <Overlay />
        <Content
          view={view}
          width={width}
          className={className}
          onPointerDownOutside={_onClose}
          onEscapeKeyDown={_onClose}
        >
          {(!nonCloseable || title) && (
            <ModalHeader hideHeader={hideHeader}>
              <Title asChild>
                <TitleText view={view}>{title}</TitleText>
              </Title>
              {!nonCloseable && (
                <DialogPrimitive.Close asChild onClick={onClose}>
                  <CloseButton view="link" colorView={view} size="small" icon={<CloseIcon size={24} />} narrow />
                </DialogPrimitive.Close>
              )}
            </ModalHeader>
          )}
          <ModalBody id="main-modal">
            {description && <ModalDescription view={view}>{description}</ModalDescription>}
            {content}
          </ModalBody>
          {footer && <Footer>{footer}</Footer>}
        </Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

const Overlay = styled(DialogPrimitive.Overlay)`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.ModalOverlay};
  z-index: ${zIndexes.modalBackground};
`

const Content = styled(DialogPrimitive.Content)<{ view: ModalProps['view']; width: ModalProps['width'] }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: ${({ width }) => width + 'px'};
  background-color: ${({ theme, view }) => (view === 'primary' ? theme.colors.White : theme.colors.HeavyMetal)};
  z-index: ${zIndexes.modalContent};
`

export const ModalHeader = styled.div<{ hideHeader: boolean }>`
  display: ${({ hideHeader }) => (hideHeader ? 'contents' : 'flex')};
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 20px 24px;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Iron};
`

const Title = styled(DialogPrimitive.Title)`
  color: ${({ theme }) => theme.colors.Mouse};
  width: 100%;
  margin-right: auto;
`

const TitleText = styled.h3<{ view: ModalProps['view'] }>`
  max-width: 100%;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme, view }) => (view === 'primary' ? theme.colors.HeavyMetal : theme.colors.White)};
`

export const CloseButton = styled(Button)<{ colorView: ModalProps['view'] }>`
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${({ theme, colorView }) => (colorView === 'primary' ? theme.colors.CapeCod : theme.colors.Edward)};
  z-index: ${zIndexes.modalContent};
`

export const ModalBody = styled.div`
  width: 100%;
  padding: 24px;
`

export const ModalDescription = styled(DialogPrimitive.Description)<{ view: ModalProps['view'] }>`
  margin: 0 0 24px;
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme, view }) => (view === 'primary' ? theme.colors.CapeCod : theme.colors.Edward)};
`

const Footer = styled.div`
  width: 100%;
  padding: 24px;
`
