import { ChainId } from '@usedapp/core'
import { SupportedChainId } from 'constants/chain'
import { environment } from 'config/environment'
import { isFeatureEnabled } from 'utils/featureFlags/isFeatureEnabled'

export enum Subgraph {
  Structured,
}

const THE_GRAPH_API_KEY = process.env.THE_GRAPH_API_KEY

export const SUBGRAPH_URLS: Record<Subgraph, Record<SupportedChainId, string>> = {
  [Subgraph.Structured]: {
    [ChainId.Mainnet]: getMainnetGraphURL(),
    [ChainId.Sepolia]: 'https://api.studio.thegraph.com/query/77776/aip-asset-sepolia/version/latest',
  },
} as const

function getMainnetGraphURL() {
  if (isFeatureEnabled('queryDecentralizedGraph') && environment.isProduction && THE_GRAPH_API_KEY) {
    return `https://gateway-arbitrum.network.thegraph.com/api/${THE_GRAPH_API_KEY}/subgraphs/id/B6ZcaV2PiEpMkdMRsLCDQPihwMxLRyKhLDzvNsVfUtB8`
  }

  return 'https://api.studio.thegraph.com/query/77774/aip-asset-mainnet/version/latest'
}
