import * as CheckboxComponent from '@radix-ui/react-checkbox'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Transitions } from 'styles'

import { CheckmarkIcon } from '../Icons'

export interface CheckboxButtonProps {
  checked?: boolean
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  id: string
  name?: string
  onChange?: (value: boolean) => void
  required?: boolean
  value?: string
}

export const CheckboxButton = forwardRef<HTMLButtonElement, CheckboxButtonProps>(function Checkbox(
  { id, name, value, checked, defaultChecked, disabled, required, onChange, className },
  ref,
) {
  return (
    <CheckboxContainer
      checked={checked}
      className={className}
      defaultChecked={defaultChecked}
      disabled={disabled}
      id={id}
      name={name}
      onCheckedChange={onChange}
      ref={ref}
      required={required}
      role="checkbox"
      value={value}
    >
      <CheckboxIndicator>
        <CheckmarkIcon size={16} />
      </CheckboxIndicator>
    </CheckboxContainer>
  )
})

const defaultStylesHover = css`
  border-color: ${({ theme }) => theme.colors.HeavyMetal};
`

const defaultStyles = css`
  content: 'defaultStyles';
  border-color: ${({ theme }) => theme.colors.Iron};
  background-color: ${({ theme }) => theme.colors.White};
  cursor: pointer;

  &:hover {
    ${defaultStylesHover}
  }
  &:focus-visible {
    ${defaultStylesHover}
  }
  &:active {
    border-color: ${({ theme }) => theme.colors.HeavyMetal};
  }
`

const defaultDisabledStylesHover = css`
  border-color: ${({ theme }) => theme.colors.GrayNurse};
  background-color: ${({ theme }) => theme.colors.White};
  cursor: not-allowed;
`

const defaultDisabledStyles = css`
  content: 'defaultDisabledStyles';
  ${defaultDisabledStylesHover}

  &:hover {
    ${defaultDisabledStylesHover}
  }
  &:focus-visible {
    ${defaultDisabledStylesHover}
  }
  &:active {
    ${defaultDisabledStylesHover}
  }
`

const checkedStylesHover = css`
  border-color: ${({ theme }) => theme.colors.HeavyMetal};
  background-color: ${({ theme }) => theme.colors.HeavyMetal};
`

const checkedStyles = css`
  content: 'checkedStyles';
  border-color: ${({ theme }) => theme.colors.HeavyMetal};
  background-color: ${({ theme }) => theme.colors.HeavyMetal};
  cursor: pointer;

  &:hover {
    ${checkedStylesHover}
  }
  &:focus-visible {
    ${checkedStylesHover}
  }
  &:active {
    ${checkedStylesHover}
  }
`

const checkedDisabledStylesHover = css`
  border-color: ${({ theme }) => theme.colors.Mouse};
  background-color: ${({ theme }) => theme.colors.Mouse};
  cursor: not-allowed;
`

const checkedDisabledStyles = css`
  content: 'checkedDisabledStyles';
  ${checkedDisabledStylesHover}

  &:hover {
    ${checkedDisabledStylesHover}
  }
  &:focus-visible {
    ${checkedDisabledStylesHover}
  }
  &:active {
    ${checkedDisabledStylesHover}
  }
`

const CheckboxContainer = styled(CheckboxComponent.Root)`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  margin: 0;
  padding: 0;
  outline: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: ${Transitions.all};

  &:not([data-disabled][data-state='checked']) {
    ${defaultStyles};
    content: 'defaultStyles';
  }

  &:not([data-disabled])[data-state='checked'] {
    ${checkedStyles};
    content: 'checkedStyles';
  }

  &[data-disabled]:not([data-state='checked']) {
    ${defaultDisabledStyles};
    content: 'defaultDisabledStyles';
  }

  &[data-disabled][data-state='checked'] {
    ${checkedDisabledStyles};
    content: 'checkedDisabledStyles';
  }
`

const CheckboxIndicator = styled(CheckboxComponent.Indicator)`
  display: inline-block;
  top: 3px;
  left: 3px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: ${({ theme }) => theme.colors.White};
  overflow: hidden;
  transition: ${Transitions.all};

  & > svg {
    position: absolute;
  }
`
