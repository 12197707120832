// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jazzicon from '@metamask/jazzicon'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { BorderRadiuses } from 'styles'

interface Props {
  address: string
  onClick?: () => void
}

const JAZZICON_SIZE = 34

export function AccountIcon({ address, onClick }: Props) {
  const accountIconRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!accountIconRef.current) {
      return
    }

    accountIconRef.current.innerHTML = ''
    accountIconRef.current.appendChild(jazzicon(JAZZICON_SIZE, parseInt(address.slice(2, 10), 16)))
  }, [accountIconRef, address])

  return <AccountIconContainer ref={accountIconRef} onClick={onClick} />
}

export const AccountIconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${BorderRadiuses.round};
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.Edward};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`
