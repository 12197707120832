import { useEffect, useState } from 'react'
import { BREAKPOINTS } from 'styles/mediaQueries'

type BreakpointType = keyof typeof BREAKPOINTS

export const useWidthBreakpoint = (width: BreakpointType) => {
  const [breakpoint, setBreakpoint] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 0) {
        if (window.innerWidth <= BREAKPOINTS[width] && !breakpoint) {
          setBreakpoint(true)
        }
        if (window.innerWidth > BREAKPOINTS[width] && breakpoint) {
          setBreakpoint(false)
        }
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [width, breakpoint])
  return breakpoint
}
