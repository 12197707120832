import { CopyButton } from 'components/atoms/CopyButton'
import styled from 'styled-components'

export function AccountSectionButtons({ address }: { address: string }) {
  return (
    <Container>
      <CopyButton value={address} color="inherit" text="Click to Copy" />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.colors.Elm};
`
