import { ConnectedWith } from 'components/molecules/ConnectedWith'
import { Account } from 'components/molecules/Account/Account'
import { Separator } from 'components/atoms/Separator'
import { AccountSectionButtons } from './AccountSectionButtons'
import { useMemo } from 'react'
import { useWidthBreakpoint } from 'hooks'
import { ExitIcon } from 'components/atoms/Icons'
import { Buttons, ModalButton } from '../ModalButtons'
import styled from 'styled-components'
import { Column } from 'components/atoms/Column'
import { Button } from 'components/atoms/Button'
import { useUser } from '@supabase/auth-helpers-react'
import { useIsConnectedManager } from './hooks/useIsConnectedManager'
import { Loader } from 'components/molecules/Loader'

interface Props {
  address: string
  disconnectWallet: () => void
  switchWallet: () => void
  activeConnector: string
  onClose: () => void
}

const TABLET_ADDRESS_SHORTEN_OPTIONS = { limitBefore: 15, limitAfter: 12 }
const MOBILE_ADDRESS_SHORTEN_OPTIONS = { limitBefore: 5, limitAfter: 8 }

export function AccountSection({ disconnectWallet, address, switchWallet, activeConnector, onClose }: Props) {
  const user = useUser()
  const isTabletLayout = useWidthBreakpoint('tabletAndSmaller')
  const isMobileLayout = useWidthBreakpoint('largeMobileAndSmaller')
  const { isConnectedManager, isLoading } = useIsConnectedManager(!!address)

  const accountFormatting = useMemo(() => {
    if (isMobileLayout) {
      return { shortenOptions: MOBILE_ADDRESS_SHORTEN_OPTIONS }
    }

    if (isTabletLayout) {
      return { shortenOptions: TABLET_ADDRESS_SHORTEN_OPTIONS }
    }

    return { showFullAddress: true }
  }, [isTabletLayout, isMobileLayout])

  if (isLoading) {
    return <StyledLoader size={40} centered />
  }

  return (
    <>
      <ConnectSection>
        <ConnectedWith activeConnector={activeConnector} />
        {!isConnectedManager && (
          <Buttons>
            <ModalButton view="secondary" onClick={switchWallet}>
              Switch Wallet
            </ModalButton>
            <ModalButton view="danger" onClick={disconnectWallet}>
              Disconnect Wallet
            </ModalButton>
          </Buttons>
        )}
      </ConnectSection>
      <Account address={address} button={<AccountSectionButtons address={address} />} {...accountFormatting} />
      {user && (
        <>
          <Separator />
          <Button view="danger" icon={<ExitIcon />} onClick={onClose} wide>
            {isConnectedManager ? 'Log out and disconnect wallet' : 'Log out'}
          </Button>
        </>
      )}
    </>
  )
}

const ConnectSection = styled(Column)`
  gap: 8px;
`

const StyledLoader = styled(Loader)`
  margin-bottom: 64px;
`
