import { ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react'
import { Modal, ModalHeader, ModalProps } from '../'
import styled from 'styled-components'
import { AccountSection } from './AccountSection'
import { ConnectWalletSection } from './ConnectWalletSection'
import { useWidthBreakpoint } from 'hooks/useWidthBreakpoint'
import { useWalletConnector } from 'providers/WalletConnectorProvider'
import { Column } from 'components/atoms/Column'
import { LogoutSection } from './LogoutSection'
import { accountModalReducer } from 'components/organisms/Modals/AccountModal/reducers/accountModalReducer'

export type Props =
  | {
      trigger: ReactNode
    }
  | {
      open: boolean
      setOpen: (arg: boolean) => void
    }

export const AccountModal = (props: Props) => {
  const { connectors, activeConnector, account, deactivate } = useWalletConnector()
  const [state, dispatch] = useReducer(accountModalReducer, { showAccount: !!account, showLogoutModal: false })

  const { showAccount, showLogoutModal } = state

  const onClose = useCallback(() => {
    dispatch({ type: 'onLogout', payload: account })

    if ('setOpen' in props) {
      props.setOpen(false)
    }
  }, [account, props])

  const onShowLogout = () => {
    dispatch({ type: 'showLogoutModal' })
  }

  const switchWallet = () => dispatch({ type: 'toggleAccount' })

  const trigger = 'trigger' in props && props.trigger
  const opened = 'open' in props ? props.open : undefined
  const tabletLayout = useWidthBreakpoint('tabletAndSmaller')
  const title = showAccount ? 'Your account' : 'Connect wallet'

  const modalContent = useMemo(() => {
    if (showLogoutModal) {
      return <LogoutSection onClose={onClose} />
    }
    if (!showAccount) {
      return <ConnectWalletSection connectors={connectors} />
    }

    return (
      account &&
      activeConnector && (
        <AccountSection
          activeConnector={activeConnector}
          address={account}
          disconnectWallet={deactivate}
          switchWallet={switchWallet}
          onClose={onShowLogout}
        />
      )
    )
  }, [account, activeConnector, connectors, deactivate, showAccount, showLogoutModal, onClose])

  useEffect(() => {
    dispatch({ type: 'toggleAccount', payload: account })
  }, [account])

  return (
    <StyledAccountModal
      width={tabletLayout ? 400 : 562}
      opened={opened}
      onClose={onClose}
      title={showLogoutModal ? undefined : title}
      view={showLogoutModal ? 'secondary' : 'primary'}
      content={<Container>{modalContent}</Container>}
      trigger={trigger}
    />
  )
}

export const Container = styled(Column)`
  margin: 4px 0;
  gap: 24px;
`

export const StyledAccountModal = styled(Modal)<Pick<ModalProps, 'title'>>`
  ${ModalHeader} {
    border-bottom: ${({ title }) => !title && 'none'};
  }
`
