import { AccountIcon, AccountIconContainer } from 'components/atoms/AccountIcon/AccountIcon'
import styled from 'styled-components'
import { forwardRef, MouseEvent, ReactNode, useCallback } from 'react'
import { shortAddress } from 'utils'
import { Row } from 'components/atoms/Row'
import { Column } from 'components/atoms/Column'
import { useUser } from '@supabase/auth-helpers-react'
import { Username } from 'components/Login/UserAvatar'
import { Overflows } from 'styles'
import { useEtherscanLink } from 'hooks'
import Link from 'next/link'

export interface Props {
  address: string
  className?: string
  shortenOptions?: { limitBefore?: number; limitAfter?: number }
  button?: ReactNode
  showFullAddress?: boolean
  onClick?: (event: MouseEvent<HTMLElement>) => void
}

export const Account = forwardRef<HTMLDivElement, Props>(
  ({ address, className, shortenOptions, button, showFullAddress, onClick }, ref) => {
    const user = useUser()
    const link = useEtherscanLink(address)
    const onAccountClick = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        if (!onClick) {
          return
        }
        onClick(event)
        event.preventDefault()
      },
      [onClick],
    )

    const addressToDisplay = showFullAddress
      ? address
      : shortAddress(address, shortenOptions?.limitBefore, shortenOptions?.limitAfter)

    return (
      <AccountContainer className={className} ref={ref} onClick={onAccountClick}>
        <Wrapper>
          <AccountIcon address={address} />
          <AccountName>
            {user && <Username bold>{user.email}</Username>}
            <Address href={link} target="_blank">
              {addressToDisplay}
            </Address>
          </AccountName>
        </Wrapper>
        {button}
      </AccountContainer>
    )
  },
)
Account.displayName = 'Account'

const Address = styled(Link)`
  color: ${({ theme }) => theme.colors.Elm};
`

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  ${Address} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-decoration: underline;
  }

  ${AccountIconContainer} {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    word-break: break-all;
    text-align: start;
  }

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    max-width: 100%;
  }
}
`

export const AccountName = styled(Column)`
  ${Overflows.Dots}
`

export const Wrapper = styled(Row)`
  gap: 8px;
  ${Overflows.Dots}
`
