import styled from 'styled-components'
import { Button } from 'components/atoms/Button'
import { Row } from 'components/atoms/Row'

export const Buttons = styled(Row)`
  gap: 16px;
  justify-content: center;
  width: 100%;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    flex-direction: column;
  }
`

export const ModalButton = styled(Button)`
  width: 50%;
  min-width: unset;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    width: 100%;
  }
`
