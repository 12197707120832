import { getSdk as getSdkCarbon } from 'generated/graphql/structured-portfolios/server'
import { getSubgraphData } from '../../utils'
import { Subgraph } from '../../constants'
import { ChainId } from '@usedapp/core'
import { toGraphId } from 'utils'
import { BigNumber, constants } from 'ethers'
import { dollar } from 'constants/currency'
import { ManagerStatistics } from 'types/Manager'
import { SupportedChainId } from 'constants/chain'

export async function getManagerStatistics(
  chainId: SupportedChainId = ChainId.Mainnet,
  manager: string,
): Promise<ManagerStatistics> {
  const data = await getSubgraphData(Subgraph.Structured, chainId, getSdkCarbon, 'getCarbonManagerStatistics', {
    address: toGraphId(manager),
  })

  const totalRaised =
    data?.manager?.raisedTotals.reduce((totalAmount, entry) => {
      const amount = BigNumber.from(entry.amount)
      if (entry.token) {
        return totalAmount.add(amount.div(Math.pow(10, entry.token.decimals - dollar.decimals)))
      }
      return totalAmount.add(constants.Zero)
    }, constants.Zero) ?? constants.Zero

  return {
    totalRaised: totalRaised.toString(),
    portfoliosLaunched: data?.manager?.portfoliosLaunched ?? 0,
  }
}
