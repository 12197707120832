import styled from 'styled-components'
import { Row } from 'components/atoms/Row'
import { Separator } from 'components/atoms/Separator'
import { Text } from 'components/atoms/Text'

export const SeparatorWithText = ({ text, dimText }: { text: string; dimText?: boolean }) => (
  <Wrapper>
    <Separator />
    <Text color={dimText ? 'extra-light' : 'dark'}>{text}</Text>
    <Separator />
  </Wrapper>
)

const Wrapper = styled(Row)`
  width: 100%;
  gap: 10px;
`
