import { SdkFunctionWrapper } from 'generated/graphql/structured-portfolios/server'
import { Subgraph, SUBGRAPH_URLS } from '../constants'
import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import { SupportedChainId } from 'constants/chain'

export async function getSubgraphData<
  QueryName extends string,
  Variables,
  SDK extends Record<
    QueryName,
    (variables: Variables, requestHeaders?: Dom.RequestInit['headers']) => ReturnType<SDK[QueryName]> | undefined
  >,
>(
  subgraph: Subgraph,
  chainId: SupportedChainId,
  fn: (client: GraphQLClient, withWrapper?: SdkFunctionWrapper) => SDK,
  queryName: QueryName,
  variables: Variables,
): Promise<ReturnType<SDK[QueryName]> | undefined> {
  try {
    const subgraphUrl = SUBGRAPH_URLS[subgraph][chainId]
    const client = fn(new GraphQLClient(subgraphUrl))
    return await client[queryName](variables)
  } catch {
    return undefined
  }
}
