import Image from 'next/image'
import DefaultUserImage from '/public/images/user-image.png'
import styled from 'styled-components'
import { Text } from 'components/atoms/Text'
import { BorderRadiuses, Overflows } from 'styles'
import { Row } from 'components/atoms/Row'
import { forwardRef } from 'react'

interface Props {
  onClick?: () => void
}

export const UserAvatar = forwardRef<HTMLDivElement, Props>(function UserAvatar({ onClick }: Props, ref) {
  return (
    <UserImage ref={ref} onClick={onClick}>
      <Image src={DefaultUserImage} alt="User image" height={35} width={35} />
    </UserImage>
  )
})

export const UserSection = styled(Row)`
  gap: 8px;
  ${Overflows.Dots}
`
export const UserImage = styled(Row)`
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.colors.Edward};
  border-radius: ${BorderRadiuses.round};
  flex-shrink: 0;
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  & img {
    border-radius: ${BorderRadiuses.round};
  }
`
export const Username = styled(Text)`
  width: 100%;
  ${Overflows.Dots}
`
