import { SupportedConnector } from 'config'
import { MetaMaskIcon, WalletConnectIcon } from 'components/atoms/Icons'

interface Props {
  connector: SupportedConnector
}

const iconMap: Record<SupportedConnector, JSX.Element> = {
  Metamask: <MetaMaskIcon />,
  WalletConnectV2: <WalletConnectIcon />,
}

export function ConnectorIcon({ connector }: Props) {
  return iconMap[connector]
}
