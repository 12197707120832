interface State {
  showAccount: boolean
  showLogoutModal: boolean
}

type Action =
  | { type: 'toggleAccount'; payload?: string }
  | { type: 'onLogout'; payload?: string }
  | { type: 'showLogoutModal' }

export const accountModalReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'onLogout':
      return { showLogoutModal: false, showAccount: !!action.payload }
    case 'showLogoutModal':
      return { ...state, showLogoutModal: true }
    case 'toggleAccount':
      return { ...state, showAccount: action.payload === 'undefined' ? !state.showAccount : !!action.payload }
    default:
      return state
  }
}
