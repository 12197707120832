import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { CheckboxButton, CheckboxButtonProps } from 'components/atoms/CheckboxButton'
import { Row } from 'components/atoms/Row'
import { FormDescriptionText } from 'components/atoms/Form'

export interface CheckboxProps extends CheckboxButtonProps {
  label: string | ReactNode
  width?: string | number
}

export const Checkbox = ({ id, label, width, disabled, ...props }: CheckboxProps) => {
  return (
    <CheckboxWrapper width={width}>
      <CheckboxButton id={id} disabled={disabled} {...props} />
      <CheckboxLabel htmlFor={id} disabled={disabled}>
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled(Row)<Pick<CheckboxProps, 'width'>>`
  gap: 8px;
  width: ${({ width }) => (width ? `${width}` : '100%')};
`

const disableTextstyle = css`
  color: ${({ theme }) => theme.colors.Edward};
`

const CheckboxLabel = styled.label<Pick<CheckboxButtonProps, 'disabled'>>`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  ${({ disabled }) => disabled && disableTextstyle}
`
export const CheckboxDescription = styled(FormDescriptionText)<Pick<CheckboxButtonProps, 'disabled'>>`
  ${({ disabled }) => disabled && disableTextstyle}
`
