import styled, { css } from 'styled-components'

import { Transitions } from 'styles'
import { CancelIcon, CheckCircleIcon, ErrorIcon } from 'components/atoms/Icons'

interface StatusIconProps {
  iconSize?: number
  innerIconSize?: number
  type: 'success' | 'fail' | 'rejected'
}

export const StatusIcon = ({ iconSize = 50, type }: StatusIconProps) => {
  switch (type) {
    case 'success':
      return (
        <StatusSuccessIcon>
          <CheckCircleIcon size={iconSize} />
        </StatusSuccessIcon>
      )
    case 'fail':
      return (
        <StatusFailIcon>
          <CancelIcon size={iconSize} />
        </StatusFailIcon>
      )
    case 'rejected':
      return (
        <StatusRejectedIcon>
          <ErrorIcon size={iconSize} />
        </StatusRejectedIcon>
      )
  }
}

export const BaseStatusIcon = styled.div<{ size?: number; borderSize?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => css`
    min-width: ${size}px;
    width: ${size}px;
    max-width: ${size}px;
    min-height: ${size}px;
    height: ${size}px;
    max-height: ${size}px;
    border-radius: ${size}px;
  `}
  border: ${({ borderSize }) => borderSize ?? 1}px solid transparent;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
  transition: ${Transitions.all};
`
export const StatusFailIcon = styled(BaseStatusIcon)`
  color: ${({ theme }) => theme.colors.PersianRed};
`

const StatusSuccessIcon = styled(BaseStatusIcon)`
  color: ${({ theme }) => theme.colors.Emerald};
`

const StatusRejectedIcon = styled(BaseStatusIcon)`
  color: ${({ theme }) => theme.colors.Fulvous};
`
