import { Connectors } from 'providers/WalletConnectorProvider'
import { useState } from 'react'
import { LinkButton } from 'components/atoms/LinkButton'
import styled from 'styled-components'
import { Checkbox } from 'components/molecules/Checkbox'
import { Button } from 'components/atoms/Button'
import { SupportedConnector } from 'config'
import { ConnectorIcon } from 'components/molecules/ConnectorIcon'
import { LearnWalletLink } from 'components/molecules/LearnWalletLink'
import { useDetectIPhone } from 'hooks/useDetectIPhone'
import { TextInline } from 'components/atoms/Text'
import { getMarketingSiteUrl } from 'utils/getMarketingSiteUrl'

interface ButtonProps {
  disabled: boolean
}

export function ConnectWalletSection({ connectors }: { connectors: Connectors }) {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const handleAcceptTerms = () => setTermsAccepted(!termsAccepted)
  const isIPhone = useDetectIPhone()

  return (
    <>
      <SectionTop>
        <Checkbox
          width="unset"
          id="termsAcceptance"
          label={
            <TextInline color="dark">
              By connecting I accept Archblock{' '}
              <LinkButton inline href={getMarketingSiteUrl('/legal/invest/terms-of-service')} blank>
                Terms of Use
              </LinkButton>{' '}
              and{' '}
              <LinkButton inline href={getMarketingSiteUrl('/legal/invest/privacy-policy')} blank>
                Privacy Policy
              </LinkButton>
            </TextInline>
          }
          onChange={handleAcceptTerms}
        />
      </SectionTop>
      <ButtonsContainer>
        {Object.entries(connectors).map(([key, { activate, name }]) => {
          return (
            <StyledButton
              view="secondary"
              key={key}
              disabled={!termsAccepted || (isIPhone && name === 'MetaMask')}
              onClick={activate}
            >
              <WalletButtonInner>
                <ConnectorIcon connector={key as SupportedConnector} />
                {name}
              </WalletButtonInner>
            </StyledButton>
          )
        })}
      </ButtonsContainer>
      <StyledLearnWalletLink />
    </>
  )
}

const SectionTop = styled.div`
  display: flex;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    flex-direction: column;
  }
`

const StyledLearnWalletLink = styled(LearnWalletLink)`
  align-items: center;
  justify-content: center;
`

const StyledButton = styled(Button)<ButtonProps>`
  border: 1px solid ${({ theme }) => theme.colors.Mouse};
  width: 50%;
  min-width: unset;
  height: unset;
  background: ${({ disabled, theme }) => (disabled ? theme.colors.GrayNurse : 'transparent')};

  &:hover {
    border-color: ${({ disabled, theme }) => (disabled ? theme.colors.Mouse : theme.colors.HeavyMetal)};
    background: ${({ disabled, theme }) => (disabled ? theme.colors.GrayNurse : 'transparent')};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.Text0 : 'initial')};
  }

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: 100%;
  }
`

const WalletButtonInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  gap: 5px;
`
