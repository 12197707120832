import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import copyToClipboard from 'copy-to-clipboard'

import { CopyIcon } from 'components/atoms/Icons'
import { Tooltip } from 'components/atoms/Tooltip'

export interface CopyButtonProps {
  size?: number
  value: string
  text?: ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  color?: 'light' | 'dark' | 'inherit'
}

export const CopyButton = ({ size = 24, value, text, side, color = 'light' }: CopyButtonProps) => {
  const [tooltipText, setTooltipText] = useState(text)
  const [open, setOpen] = useState<boolean>(false)

  return (
    <CopyTooltip side={side} tooltip={tooltipText} open={open}>
      <CopyButtonWrapper
        onClick={(e) => {
          copyToClipboard(value)
          setTooltipText('Copied')
          setOpen(true)
          e.preventDefault()
        }}
        onMouseOver={(e) => {
          setTooltipText(text)
          setOpen(true)
          e.preventDefault()
        }}
        onMouseOut={(e) => {
          setOpen(false)
          e.preventDefault()
        }}
        color={color}
      >
        <CopyIcon size={size} />
      </CopyButtonWrapper>
    </CopyTooltip>
  )
}

const CopyButtonWrapper = styled.div<Pick<CopyButtonProps, 'color'>>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme, color }) => {
    switch (color) {
      case 'light':
        return theme.colors.White
      case 'dark':
      default:
        return theme.colors.Text03
      case 'inherit':
        return 'inherit'
    }
  }};
`

const CopyTooltip = styled(Tooltip)`
  flex-shrink: 0;
  margin-left: auto;
`
