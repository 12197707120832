import type { SupabaseClient } from '@supabase/supabase-js'
import type { Database } from 'integrations/supabase/generated.types'
import { sendExceptionToSentry } from 'utils/api/sendExceptionToSentry'

export async function getManagerFromStore(userId: string | undefined, supabase: SupabaseClient<Database>) {
  if (!userId) {
    return {
      managerData: null,
      errorCode: null,
    }
  }

  const managerResponse = await supabase
    ?.from('Manager')
    .select(managerFieldsQuery)
    .eq('userId', userId)
    .order('orderNumber', { foreignTable: 'ManagerAttachment' })
    .order('orderNumber', { foreignTable: 'ManagerDescriptionSection' })
    .order('orderNumber', { foreignTable: 'ManagerHighlight' })
    .order('orderNumber', { foreignTable: 'ManagerTeam.TeamMember' })

  const error = managerResponse?.error
  if (error) {
    await sendExceptionToSentry(error)
  }
  const errorCode = error ? managerResponse.status : null

  return {
    managerData: managerResponse?.data?.[0],
    errorCode,
  }
}

const managerFieldsQuery = `
  id,
  name,
  address,
  chainId,
  imageUrl,
  userId,
  ManagerTeam (
    description,
    TeamMember (
      orderNumber,
      name,
      role,
      linkedin,
      imageUrl
    )
  ),
  ManagerHighlight (
    orderNumber,
    title,
    description
  ),
  ManagerAttachment (
    url,
    filename,
    type,
    orderNumber,
    visibility
  ),
  ManagerDescriptionSection (
    orderNumber,
    title,
    content
  ),
  ContactMethod (
    service,
    value
  )
`
