import styled from 'styled-components'
import { Column } from 'components/atoms/Column'
import { Text } from 'components/atoms/Text'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 40px;
  width: 100%;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    row-gap: 32px;
  }
`

export const FormWrapper = styled(Column)`
  gap: 40px;
  width: 100%;
  max-width: 552px;

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    max-width: 100%;
  }
`

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 40px;
  overflow: visible;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    grid-template-columns: 1fr;
  }
`
export const FormDescriptionText = styled(Text)`
  margin-top: 4px;
`
