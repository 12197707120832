import * as Types from './types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { gql } from 'graphql-request';
export const TokenFieldsFragmentDoc = gql`
    fragment TokenFields on Token {
  id
  chainId
  symbol
  name
  decimals
}
    `;
export const StructuredAssetPortfolioFieldsFragmentDoc = gql`
    fragment StructuredAssetPortfolioFields on StructuredAssetPortfolio {
  outstandingAssets
  outstandingPrincipal
  latestReportId
  currentRoi
}
    `;
export const CommonCarbonStructuredPortfolioFieldsFragmentDoc = gql`
    fragment CommonCarbonStructuredPortfolioFields on StructuredPortfolio {
  __typename
  id
  name
  chainId
  status
  manager {
    id
  }
  startDate
  endDate
  creationDate
  duration
  startDeadline
  minimumSize
  totalAccruedInterest
  totalInterestDisbursed
  latestCheckpoint {
    trancheTotalSupplies
    trancheTotalAssets
    portfolioTotalAssets
    timestamp
  }
  asset {
    ...TokenFields
  }
  ... on StructuredAssetPortfolio {
    ...StructuredAssetPortfolioFields
  }
}
    ${TokenFieldsFragmentDoc}
${StructuredAssetPortfolioFieldsFragmentDoc}`;
export const TrancheFieldsFragmentDoc = gql`
    fragment TrancheFields on TrancheVault {
  id
  depositController
  withdrawController
  managerFeeRate
  redemptions {
    id
    withdrawType
    assets
  }
  minimumDepositController {
    minimumDeposit
    ceiling
  }
}
    `;
export const CarbonStructuredPortfolioFieldsFragmentDoc = gql`
    fragment CarbonStructuredPortfolioFields on StructuredPortfolio {
  ...CommonCarbonStructuredPortfolioFields
  tranches {
    ...TrancheFields
  }
}
    ${CommonCarbonStructuredPortfolioFieldsFragmentDoc}
${TrancheFieldsFragmentDoc}`;
export const CarbonInvestmentFieldsFragmentDoc = gql`
    fragment CarbonInvestmentFields on TrancheVaultAction {
  shares
  assets
  action
  trancheVault {
    id
    withdrawController
    portfolio {
      id
    }
  }
}
    `;
export const FixedInterestOnlyLoanFieldsFragmentDoc = gql`
    fragment FixedInterestOnlyLoanFields on FixedInterestOnlyLoan {
  id
  chainId
  periodDuration
  periodCount
  periodsRepaid
  periodicPayment
  principal
  recipient
  endDate
  status
  currentPeriodEndDate
  gracePeriod
  baseAnnualRateInBPS
}
    `;
export const MultiWithdrawalControllerRedemptionFieldsFragmentDoc = gql`
    fragment MultiWithdrawalControllerRedemptionFields on MultiWithdrawalControllerRedemption {
  assets
  shares
  fee
  withdrawType
  timestamp
  owner
  txHash
  trancheVault {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonStructuredPortfoliosDocument = gql`
    query getCarbonStructuredPortfolios {
  structuredPortfolios(
    orderBy: startDate
    orderDirection: desc
    where: {status_in: [CapitalFormation, Live]}
  ) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonStructuredPortfoliosByAddressesDocument = gql`
    query getCarbonStructuredPortfoliosByAddresses($addresses: [ID!]!) {
  structuredPortfolios(
    orderBy: startDate
    orderDirection: desc
    where: {status_in: [CapitalFormation, Live], id_in: $addresses}
  ) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonStructuredPortfolioDocument = gql`
    query getCarbonStructuredPortfolio($address: ID!) {
  structuredPortfolio(id: $address) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetStructuredPortfoliosDocument = gql`
    query getStructuredPortfolios($addresses: [ID!]!) {
  structuredPortfolios(where: {id_in: $addresses}) {
    ...CarbonStructuredPortfolioFields
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonManagerPortfoliosDocument = gql`
    query getCarbonManagerPortfolios($address: ID!) {
  manager(id: $address) {
    portfolios {
      ...CarbonStructuredPortfolioFields
    }
  }
}
    ${CarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetCarbonManagerDocument = gql`
    query getCarbonManager($address: ID!) {
  manager(id: $address) {
    id
    isWhitelisted
  }
}
    `;
export const GetCarbonManagerStatisticsDocument = gql`
    query getCarbonManagerStatistics($address: ID!) {
  manager(id: $address) {
    raisedTotals {
      amount
      token {
        decimals
        id
      }
    }
    portfoliosLaunched
  }
}
    `;
export const GetCarbonStructuredPortfolioDepositsDocument = gql`
    query getCarbonStructuredPortfolioDeposits($address: ID!) {
  structuredPortfolio(id: $address) {
    tranches {
      id
      trancheVaultActions(first: 1000, where: {action: Deposit}) {
        sender
        assets
        timestamp
        txHash
      }
    }
  }
}
    `;
export const GetCarbonStructuredPortfolioActionsDocument = gql`
    query getCarbonStructuredPortfolioActions($address: ID!) {
  structuredPortfolio(id: $address) {
    tranches {
      id
      trancheVaultActions(first: 1000) {
        action
        sender
        assets
      }
      redemptions(first: 1000) {
        withdrawType
        assets
        shares
        owner
      }
    }
  }
}
    `;
export const GetFixedInterestOnlyLoansDocument = gql`
    query getFixedInterestOnlyLoans($portfolioAddress: ID!) {
  fixedInterestOnlyLoans(where: {portfolio_: {id: $portfolioAddress}}) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const GetFixedInterestOnlyLoanDocument = gql`
    query getFixedInterestOnlyLoan($id: ID!) {
  fixedInterestOnlyLoan(id: $id) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const GetCarbonManagerLoansDocument = gql`
    query getCarbonManagerLoans($managerAddress: String!) {
  fixedInterestOnlyLoans(
    where: {portfolio_: {manager: $managerAddress}, status: Started}
  ) {
    portfolio {
      asset {
        id
        decimals
      }
    }
    principal
  }
}
    `;
export const GetStructuredInvestmentsDocument = gql`
    query getStructuredInvestments($investorAddress: String!, $trancheAddresses: [String!]!, $trancheIds: [ID!]!) {
  trancheVaultActions(
    where: {owner: $investorAddress, action: Deposit, trancheVault_in: $trancheAddresses}
  ) {
    ...CarbonInvestmentFields
  }
  multiWithdrawalControllerRedemptions(
    where: {owner: $investorAddress, trancheVault_in: $trancheAddresses}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
  trancheVaults(where: {id_in: $trancheIds}) {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CarbonInvestmentFieldsFragmentDoc}
${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}
${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetStructuredTrancheInvestmentsDocument = gql`
    query getStructuredTrancheInvestments($investorAddress: String!, $trancheAddress: String!, $trancheId: ID!) {
  trancheVaultActions(
    where: {owner: $investorAddress, action: Deposit, trancheVault: $trancheAddress}
  ) {
    ...CarbonInvestmentFields
  }
  multiWithdrawalControllerRedemptions(
    where: {owner: $investorAddress, trancheVault: $trancheAddress}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
  trancheVaults(where: {id: $trancheId}) {
    id
    withdrawController
    portfolio {
      ...CommonCarbonStructuredPortfolioFields
      expectedEquityRateFrom
      expectedEquityRateTo
    }
  }
}
    ${CarbonInvestmentFieldsFragmentDoc}
${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}
${CommonCarbonStructuredPortfolioFieldsFragmentDoc}`;
export const GetMultiWithdrawalControllerRedemptionsDocument = gql`
    query getMultiWithdrawalControllerRedemptions($trancheAddress: String!, $withdrawalType: MultiWithdrawalControllerRedemptionType!) {
  multiWithdrawalControllerRedemptions(
    where: {trancheVault: $trancheAddress, withdrawType: $withdrawalType}
  ) {
    ...MultiWithdrawalControllerRedemptionFields
  }
}
    ${MultiWithdrawalControllerRedemptionFieldsFragmentDoc}`;
export const GetLoansAndRepaymentsDocument = gql`
    query getLoansAndRepayments($portfolioAddress: ID!) {
  loanActions(where: {portfolio_: {id: $portfolioAddress}, action: LoanRepaid}) {
    action
    assets
    timestamp
    sender
    txHash
  }
  fixedInterestOnlyLoans(where: {portfolio_: {id: $portfolioAddress}}) {
    ...FixedInterestOnlyLoanFields
  }
}
    ${FixedInterestOnlyLoanFieldsFragmentDoc}`;
export const GetAssetVaultDisbursementsAndRepaymentsDocument = gql`
    query getAssetVaultDisbursementsAndRepayments($portfolioAddress: String!) {
  assetsActions(where: {portfolio: $portfolioAddress}) {
    action
    principalAmount
    interestAmount
    outstandingAssets
    sender
    timestamp
    txHash
    assetReportId
    id
  }
}
    `;
export const GetPortfoliosNamesDocument = gql`
    query getPortfoliosNames($addresses: [ID!]!) {
  structuredPortfolios(where: {id_in: $addresses}) {
    id
    name
  }
}
    `;
export const GetStructuredPortfolioTokenDocument = gql`
    query getStructuredPortfolioToken($address: ID!) {
  structuredPortfolio(id: $address) {
    id
    asset {
      ...TokenFields
    }
  }
}
    ${TokenFieldsFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getCarbonStructuredPortfolios(variables?: Types.GetCarbonStructuredPortfoliosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonStructuredPortfoliosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonStructuredPortfoliosQuery>(GetCarbonStructuredPortfoliosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonStructuredPortfolios', 'query');
    },
    getCarbonStructuredPortfoliosByAddresses(variables: Types.GetCarbonStructuredPortfoliosByAddressesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonStructuredPortfoliosByAddressesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonStructuredPortfoliosByAddressesQuery>(GetCarbonStructuredPortfoliosByAddressesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonStructuredPortfoliosByAddresses', 'query');
    },
    getCarbonStructuredPortfolio(variables: Types.GetCarbonStructuredPortfolioQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonStructuredPortfolioQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonStructuredPortfolioQuery>(GetCarbonStructuredPortfolioDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonStructuredPortfolio', 'query');
    },
    getStructuredPortfolios(variables: Types.GetStructuredPortfoliosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetStructuredPortfoliosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetStructuredPortfoliosQuery>(GetStructuredPortfoliosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStructuredPortfolios', 'query');
    },
    getCarbonManagerPortfolios(variables: Types.GetCarbonManagerPortfoliosQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonManagerPortfoliosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonManagerPortfoliosQuery>(GetCarbonManagerPortfoliosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonManagerPortfolios', 'query');
    },
    getCarbonManager(variables: Types.GetCarbonManagerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonManagerQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonManagerQuery>(GetCarbonManagerDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonManager', 'query');
    },
    getCarbonManagerStatistics(variables: Types.GetCarbonManagerStatisticsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonManagerStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonManagerStatisticsQuery>(GetCarbonManagerStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonManagerStatistics', 'query');
    },
    getCarbonStructuredPortfolioDeposits(variables: Types.GetCarbonStructuredPortfolioDepositsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonStructuredPortfolioDepositsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonStructuredPortfolioDepositsQuery>(GetCarbonStructuredPortfolioDepositsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonStructuredPortfolioDeposits', 'query');
    },
    getCarbonStructuredPortfolioActions(variables: Types.GetCarbonStructuredPortfolioActionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonStructuredPortfolioActionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonStructuredPortfolioActionsQuery>(GetCarbonStructuredPortfolioActionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonStructuredPortfolioActions', 'query');
    },
    getFixedInterestOnlyLoans(variables: Types.GetFixedInterestOnlyLoansQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetFixedInterestOnlyLoansQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetFixedInterestOnlyLoansQuery>(GetFixedInterestOnlyLoansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFixedInterestOnlyLoans', 'query');
    },
    getFixedInterestOnlyLoan(variables: Types.GetFixedInterestOnlyLoanQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetFixedInterestOnlyLoanQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetFixedInterestOnlyLoanQuery>(GetFixedInterestOnlyLoanDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFixedInterestOnlyLoan', 'query');
    },
    getCarbonManagerLoans(variables: Types.GetCarbonManagerLoansQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetCarbonManagerLoansQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetCarbonManagerLoansQuery>(GetCarbonManagerLoansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCarbonManagerLoans', 'query');
    },
    getStructuredInvestments(variables: Types.GetStructuredInvestmentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetStructuredInvestmentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetStructuredInvestmentsQuery>(GetStructuredInvestmentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStructuredInvestments', 'query');
    },
    getStructuredTrancheInvestments(variables: Types.GetStructuredTrancheInvestmentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetStructuredTrancheInvestmentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetStructuredTrancheInvestmentsQuery>(GetStructuredTrancheInvestmentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStructuredTrancheInvestments', 'query');
    },
    getMultiWithdrawalControllerRedemptions(variables: Types.GetMultiWithdrawalControllerRedemptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetMultiWithdrawalControllerRedemptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetMultiWithdrawalControllerRedemptionsQuery>(GetMultiWithdrawalControllerRedemptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getMultiWithdrawalControllerRedemptions', 'query');
    },
    getLoansAndRepayments(variables: Types.GetLoansAndRepaymentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetLoansAndRepaymentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetLoansAndRepaymentsQuery>(GetLoansAndRepaymentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLoansAndRepayments', 'query');
    },
    getAssetVaultDisbursementsAndRepayments(variables: Types.GetAssetVaultDisbursementsAndRepaymentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetAssetVaultDisbursementsAndRepaymentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetAssetVaultDisbursementsAndRepaymentsQuery>(GetAssetVaultDisbursementsAndRepaymentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAssetVaultDisbursementsAndRepayments', 'query');
    },
    getPortfoliosNames(variables: Types.GetPortfoliosNamesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetPortfoliosNamesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetPortfoliosNamesQuery>(GetPortfoliosNamesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPortfoliosNames', 'query');
    },
    getStructuredPortfolioToken(variables: Types.GetStructuredPortfolioTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<Types.GetStructuredPortfolioTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<Types.GetStructuredPortfolioTokenQuery>(GetStructuredPortfolioTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStructuredPortfolioToken', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;