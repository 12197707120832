import styled from 'styled-components'
import { Text } from 'components/atoms/Text'
import { Column } from 'components/atoms/Column'
import { LearnWalletLink } from 'components/molecules/LearnWalletLink'
import { Row } from 'components/atoms/Row'
import { BorderRadiuses } from 'styles'

export function NotConnected() {
  return (
    <Wrapper>
      <Container>
        <Eclipse />
        <Text color="dark" bold>
          Not connected with wallet
        </Text>
      </Container>
      <LearnWalletLink />
    </Wrapper>
  )
}

const Container = styled(Row)`
  gap: 8px;
`

const Eclipse = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: ${BorderRadiuses.m};
  background-color: ${({ theme }) => theme.colors.PersianRed};
`

const Wrapper = styled(Column)`
  gap: 4px;
  align-items: flex-start;
`
