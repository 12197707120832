import { IconBase, IconProps } from '../IconBase'

export const CheckmarkIcon = ({ color, size, className }: IconProps) => {
  return (
    <IconBase color={color} size={size} className={className}>
      <path
        d="M1.23694 7.75119L5.97554 12.5336C6.05576 12.6145 6.18731 12.6122 6.2646 12.5284L14.7632 3.31784"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </IconBase>
  )
}
