import { useChainMeta } from '@usedapp/core'
import { useChainId } from './useChainId'
import { SupportedChainId } from 'constants/chain'

const ADDRESS_REGEXP = /^(0x)?[0-9a-fA-F]{40}$/
const TX_HASH_REGEXP = /^(0x)?[0-9a-fA-F]{64}$/

export function useEtherscanLink(addressOrTxHash: string | undefined = '', chainId?: SupportedChainId) {
  const connectedChainId = useChainId()
  const { getExplorerTransactionLink, getExplorerAddressLink } = useChainMeta(chainId ?? connectedChainId)

  const isAddressLike = ADDRESS_REGEXP.test(addressOrTxHash)
  const isTxHashLike = TX_HASH_REGEXP.test(addressOrTxHash)

  if (!isAddressLike && !isTxHashLike) {
    return ''
  }

  return isAddressLike ? getExplorerAddressLink(addressOrTxHash) : getExplorerTransactionLink(addressOrTxHash)
}
