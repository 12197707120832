import { useState, useEffect } from 'react'

type ExtendedNavigator = Navigator & { msMaxTouchPoints: number }

export const useTouchscreenCheck = () => {
  const [isTouchscreen, setIsTouchscreen] = useState(false)

  useEffect(() => {
    const isTouch =
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as ExtendedNavigator).msMaxTouchPoints > 0
    setIsTouchscreen(isTouch)
  }, [])

  return isTouchscreen
}
