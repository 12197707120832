import { useUser } from '@supabase/auth-helpers-react'
import { useManager } from 'hooks/useManager'

export const useIsConnectedManager = (isWalletConnected: boolean) => {
  const user = useUser()
  const { manager, isLoading } = useManager(user?.id)

  if (!isWalletConnected) {
    return {
      isConnectedManager: false,
      isLoading: false,
    }
  }

  return {
    isConnectedManager: !!manager && !!manager.address && !!manager.chainId,
    isLoading: isLoading,
  }
}
