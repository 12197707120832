import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Container } from 'components/organisms/Modals'
import { ConnectWalletSection, LogoutSection, StyledAccountModal } from 'components/organisms/Modals/AccountModal'
import { useWidthBreakpoint } from 'hooks'
import { useWalletConnector } from 'providers/WalletConnectorProvider'
import { UserAccountSection } from './UserAccountSection'

export interface Props {
  trigger: ReactNode
}

export const UserModal = ({ trigger }: Props) => {
  const { connectors } = useWalletConnector()

  const [showConnectWallet, setShowConnectWallet] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const onClose = useCallback(() => {
    setShowConnectWallet(false)
    setShowLogoutModal(false)
  }, [])

  const onShowLogout = () => setShowLogoutModal(true)
  const onConnect = () => setShowConnectWallet(true)

  const tabletLayout = useWidthBreakpoint('tabletAndSmaller')
  const title = showConnectWallet ? 'Connect wallet' : 'Your account'

  const modalContent = useMemo(() => {
    if (showLogoutModal) {
      return <LogoutSection onClose={onClose} />
    }
    if (showConnectWallet) {
      return <ConnectWalletSection connectors={connectors} />
    }

    return <UserAccountSection onConnect={onConnect} onLogout={onShowLogout} />
  }, [connectors, showConnectWallet, showLogoutModal, onClose])

  return (
    <StyledAccountModal
      width={tabletLayout ? 400 : 562}
      onClose={onClose}
      title={showLogoutModal ? undefined : title}
      view={showLogoutModal ? 'secondary' : 'primary'}
      content={<Container>{modalContent}</Container>}
      trigger={trigger}
    />
  )
}
