import styled, { css } from 'styled-components'
import { Properties } from 'csstype'

export const Column = styled.div<{ alignItems?: Properties['alignItems'] }>`
  display: flex;
  flex-direction: column;
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
`
