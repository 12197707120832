import { LinkButton } from 'components/atoms/LinkButton'
import styled from 'styled-components'
import { useUser } from '@supabase/auth-helpers-react'
import { useEthers } from '@usedapp/core'
import { UserAvatar, Username, UserSection } from 'components/Login/UserAvatar'
import { UserModal } from 'components/organisms/Modals/UserModal'
import { SeparatorWithText } from 'components/molecules/SeparatorWithText'
import { Overflows } from 'styles'

export const SignInSection = () => {
  const user = useUser()
  const { account } = useEthers()

  if (user && account) return null

  const showSeparator = !user && !account

  return (
    <>
      <Wrapper>
        {user ? (
          <UserSection>
            <UserModal trigger={<UserAvatar />} />
            <Username bold>{user.email}</Username>
          </UserSection>
        ) : (
          <SignInButton view="primary" href="/signin">
            Sign in
          </SignInButton>
        )}
      </Wrapper>
      {showSeparator && (
        <SeparatorWrapper>
          <SeparatorWithText text="or" />
        </SeparatorWrapper>
      )}
    </>
  )
}

const SeparatorWrapper = styled.div`
  padding: 0 24px;
`

export const SignInButton = styled(LinkButton)`
  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    padding: 10px;
    min-width: unset;
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    width: 100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 24px;
  ${Overflows.Dots}

  @media ${({ theme }) => theme.queries.laptopAndSmaller} {
    padding: 24px 8px;

    ${UserSection} {
      flex-direction: column;
    }
    ${Username} {
      align-self: flex-start;
    }
  }

  @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
    width: 100%;
    padding: 24px;

    ${SignInButton} {
      min-width: 208px;
    }
  }
`
