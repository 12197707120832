import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import styled from 'styled-components'

import { useTouchscreenCheck } from 'hooks/useTouchscreenCheck'
import { ReactNode } from 'react'
import { popupContent, popupTriggerContainer } from '../common/styles'

export interface TooltipProps {
  className?: string
  side?: 'top' | 'right' | 'bottom' | 'left'
  stretch?: boolean
  tooltip: ReactNode
  children: ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const Tooltip = ({
  side = 'bottom',
  stretch,
  tooltip,
  children,
  open,
  onOpenChange,
  className,
}: TooltipProps) => {
  const isTouchscreen = useTouchscreenCheck()

  if (!tooltip) {
    return <>{children}</>
  }

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={isTouchscreen ? 0 : 250} open={open} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger asChild role="tooltip">
          <TooltipTriggerContainer className={className}>{children}</TooltipTriggerContainer>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipContent side={side} $stretch={stretch} sideOffset={8}>
            {tooltip}
            <TooltipPrimitive.Arrow width={11} height={5} />
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

const TooltipTriggerContainer = styled.div`
  ${popupTriggerContainer}
`

const TooltipContent = styled(TooltipPrimitive.Content)`
  ${popupContent}
`
